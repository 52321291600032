import React from "react";
import Layout from '@components/layout/layout.jsx'
import './styles.scss'
import DocumentHeadMeta from '@components/utility/SEO.jsx'

const ContactUsConfirmed = () => {

    return (
        <Layout addedClass="page--contact-us-confirmed">
            <DocumentHeadMeta title='Thank You for Contacting Us | ALL IN&reg; for LN' description="" robots="noindex" canonicalURL='https://www.allinforlupusnephritis.com/contact-us-confirmed' />
            <section className='bg-light-purple'>
                <div className="container container--narrow">
                    <h1>Thank you</h1>
                    <p className='text--black'>Thank you for your request. We will respond to your inquiry within 5 business days.</p>
                </div>
            </section>
        </Layout>
    )
}

export default ContactUsConfirmed;
